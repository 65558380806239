import classnames from 'classnames';
import { convert } from 'html-to-text';

import { useAuth, useRouter, useCompany } from '@webfx/web-hooks';
import { Icon, Link } from '@webfx/core-web';

import {
  getOptimizationType,
  getPickedBy,
  getCardProperties,
  buildUrl,
} from '@webfx/gameplan-ui/src/utils/optimization-card-utils';
import { getAssetCost } from '@webfx/contentgenius-web/src/pages/ContentPlanner/utils';

import GoalDetail from '../../GoalDetail';
import ActionButton from './ActionButton';
import Badge from './Badge';
import styles from './OptimizationCard.module.css';

const OptimizationCard = ({ projectOptimization, companyId, quarter }) => {
  const { siteId, objectId, type } = projectOptimization;
  const { url } = useRouter();
  const { isAdmin } = useAuth();
  // Define the conditions that determine if a service is excluded or considered "other"
  const isOtherService = !['todo', 'milestone', 'asset'].includes(type);

  // For non-admin users, invalid types, or other services, return the Card component
  if ((!isAdmin && type !== 'asset') || isOtherService || type === 'none') {
    return (
      <Card
        projectOptimization={projectOptimization}
        isOtherService={isOtherService}
        companyId={companyId}
        quarter={quarter}
      />
    );
  }

  return (
    <Link to={url(buildUrl({ siteId, type, objectId }))} plain={true} data-fx-name="link">
      <div className="cursor-pointer">
        <Card projectOptimization={projectOptimization} companyId={companyId} quarter={quarter} />
      </div>
    </Link>
  );
};

const Card = ({ projectOptimization, companyId, quarter }) => {
  const { isAdmin } = useAuth();
  const { data: company } = useCompany(companyId, { query: {
    $join: ['isEnterprisePricing']
  }});
  const type = getOptimizationType(projectOptimization);
  const pickedBy = getPickedBy(projectOptimization);
  const { asset, completedAt, name, goal, smePick, directorPick } = projectOptimization;
  const isAsset = type === 'asset';
  const points = getAssetCost(asset, company?.isEnterprisePricing);

  const CARD_PROPERTIES = getCardProperties(type);

  // Strip html tags from the name and remove any URLs enclosed in square brackets
  const title = convert(name?.split(' > ').pop()).replace(/\[https?:\/\/[^\]]+\]/g, '');

  return (
    <div
      className={classnames('card mb-2', styles.card, CARD_PROPERTIES.textColor, {
        [CARD_PROPERTIES.cardBackground]: completedAt,
      })}
      style={{
        boxShadow: CARD_PROPERTIES.boxShadow,
      }}
      data-fx-name="optimizationCard"
    >
      <div className="p-3">
        <div className="d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column align-items-start" data-fx-name="cardTitle">
            {isAsset && (
              <div className="d-flex flex-column">
                {asset.pageUrl && (
                  <Link href={asset.pageUrl} plain={true} data-fx-name="contentLink">
                    <div
                      className="d-flex align-items-center font-12 semibold border rounded bg-hover-blue-vivid-100 text-hover-blue-vivid-400 px-1 mb-1"
                      style={{ width: '80px' }}
                    >
                      <Icon>description</Icon>
                      <span className="ml-1" data-fx-name="content">
                        Content
                      </span>
                    </div>
                  </Link>
                )}
                <div className="d-flex align-items-start">
                  <span
                    className={classnames('text-uppercase', styles.assetType)}
                    data-fx-name="assetType"
                  >
                    {asset.assetType}
                  </span>
                  {points ? (
                    <div className="d-flex align-items-center font-11 bg-gray-100 text-primary mx-3 px-1 rounded">
                      <Icon className="font-12">local_gas_station</Icon>
                      <span>{points}</span>
                    </div>
                  ) : null}
                </div>
              </div>
            )}

            <span className={styles.optimizationName} data-fx-name="optimizationName">
              {title}
            </span>
          </div>

          {isAdmin && (
            <div className="d-flex justify-content-end">
              <ActionButton
                projectOptimization={projectOptimization}
                companyId={companyId}
                quarter={quarter}
              />
            </div>
          )}
        </div>

        <div className="mt-1">
          <GoalDetail type={type} goal={goal} />
        </div>
        <div className="d-flex align-items-center justify-content-between mt-1">
          <div className="d-flex align-items-center" style={{ gap: '5px' }}>
            {smePick || directorPick ? <Badge type={type} pickedBy={pickedBy} /> : null}

            <Badge type={type} serviceType={type} />
          </div>
          <div
            className="d-flex align-items-center align-self-end ml-auto"
            title={completedAt ? 'Completed' : 'Pending'}
            data-fx-name="status"
          >
            <Icon outlined={!completedAt} className={`font-24 ${CARD_PROPERTIES.textColor}`}>
              {completedAt ? 'check_circle' : 'access_time'}
            </Icon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptimizationCard;
