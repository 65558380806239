import { DateTime } from 'luxon';
import numbro from 'numbro';

import { Svg, Spinner, Icon } from '@webfx/core-web';
import { useCompany } from '@webfx/web-hooks';
import { getAssetCost } from '@webfx/contentgenius-web/src/pages/ContentPlanner/utils';

import Calendar from '@webfx/teamwork-web/src/assets/images/calendar.svg';

import { getMonth } from '@webfx/web-ui/src/QuarterSelector/utils';

import useActivityLog from '@webfx/gameplan-ui/src/hooks/useActivityLog';

const CalendarColumnHeader = ({ companyId, month, isFetching = false, year, optimizations }) => {
  const { data: company } = useCompany(companyId, { query: {
    $join: ['isEnterprisePricing']
  }});
  const lastDayOfMonth = DateTime.fromISO(month).endOf('month').toISODate();

  const { data: logs } = useActivityLog(companyId, {
    createdAt: { $gte: month, $lte: lastDayOfMonth },
    limit: -1,
  });

  const totalMessages = logs?.messages?.length;
  const totalCalls = logs?.calls?.length;

  const totalPoints = optimizations
    ?.filter((optimization) => optimization.type === 'asset')
    .reduce((total, optimization) => {
      const point = getAssetCost(optimization.asset, company?.isEnterprisePricing);
      return total + Number(point);
    }, 0);

  const monthInWords = getMonth(month, 'short');
  return (
    <div className="d-flex flex-column" data-fx-name="strategyColumn">
      <div className="d-flex pl-2">
        <div className="d-flex align-items-center">
          <Svg
            svgClassName="pt-sm-2 font-24 pb-2"
            svgStyle={{ width: '19px' }}
            component={'span'}
            src={Calendar}
          />
          <div className="d-flex align-items-center ml-2">
            <span className="font-weight-bolder">{monthInWords && monthInWords.toUpperCase()}</span>
            <span className="font-12 ml-1 text-gray-550">{`${year}`.substring(2)}</span>
          </div>
        </div>
        {!isFetching && (
          <div className="d-flex align-items-center ml-auto font-14 text-blue-vivid-400 semi-bold">
            {totalPoints
              ? numbro(totalPoints).format({
                  trimMantissa: true,
                  mantissa: 2,
                })
              : 0}
            <Icon className="text-blue-vivid-200 font-18">local_gas_station</Icon>
          </div>
        )}

        <div className="d-flex align-items-center ml-2">
          {isFetching ? <Spinner size="sm" /> : null}
        </div>
      </div>
      <div className="d-flex justify-content-between border mb-3 p-2 rounded">
        <div className="d-flex align-items-center">
          <Icon outlined className="text-blue-vivid-200 font-18 mr-2">
            chat_bubble
          </Icon>
          <span className="font-12">{totalMessages} Messages Exchanged</span>
        </div>
        <div className="d-flex align-items-center">
          <Icon outlined className="text-blue-vivid-200 font-18 mr-2">
            phone_in_talk
          </Icon>
          <span className="font-12">{totalCalls} Calls/Meetings</span>
        </div>
      </div>
    </div>
  );
};

export default CalendarColumnHeader;
